<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title m-0">{{ $t('property_detail.Property_Detail') }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <p><span class="font-weight-bolder">{{ $t('properties_list.Property_Type') }}:</span> {{
                                    propertyData.property_type }} </p>
                                <p><span class="font-weight-bolder">{{ $t('properties_list.city') }}:</span> {{
                                    propertyData.city }}</p>
                                <p><span class="font-weight-bolder">{{ $t('properties_list.state') }}:</span> {{
                                    propertyData.state }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Country') }}:</span> {{
                                    propertyData.country }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Guests') }}:</span> {{
                                    propertyData.max_guests }}</p>
                            </div>
                            <div class="col-md-4">
                                <p><span class="font-weight-bolder">{{ $t('property_detail.bedrooms') }}:</span> {{
                                    propertyData.num_bedrooms }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Bedroom_Amenities')
                                        }}:</span>
                                    {{ bedroomAmenitiesDisplay }}
                                </p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.bathrooms') }}:</span> {{
                                    propertyData.num_bathrooms }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Bathroom_Amenities')
                                        }}:</span>
                                    {{ bathroomAmenitiesDisplay }}
                                </p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Price') }}:</span> ${{
                                    propertyData.price }}</p>
                            </div>
                            <div class="col-md-4">
                                <p><span class="font-weight-bolder">{{ $t('property_detail.wifi') }}:</span> {{
                                    propertyData.wifi_available === 1 ? 'Yes' : 'No' }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.kitchen') }}:</span> {{
                                    propertyData.kitchen_available === 1 ? 'Yes' : 'No' }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.tv') }}:</span> {{
                                    propertyData.tv_available === 1 ? 'Yes' : 'No' }}</p>
                                <p><span class="font-weight-bolder">{{ $t('property_detail.Description') }}:</span> {{
                                    propertyData.property_description }}</p>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <!-- Images Section -->
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5>Property Owner Details</h5>
                                        <p><span class="font-weight-bolder">Name:</span> {{ propertyData.user.name }}
                                        </p>
                                        <p><span class="font-weight-bolder">Email:</span> {{ propertyData.user.email }}
                                        </p>
                                        <p><span class="font-weight-bolder">Phone:</span> {{
                                            propertyData.user.mobile_phone }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>{{ $t('Propertis.House_Rules') }}</h5>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Check_in_after') }}:</span>
                                            {{ formatTime(propertyData.checkin_after) }}
                                        </p>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Check_out_before')
                                                }}:</span> {{ formatTime(propertyData.checkout_before) }}
                                        </p>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Children') }}:</span> {{
                                            propertyData.children }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Events') }}:</span> {{
                                            propertyData.Events }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Pets') }}:</span> {{
                                            propertyData.Pets }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Propertis.Smoking') }}:</span> {{
                                            propertyData.Smoking }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>{{ $t('Propertis.Damage_and_incidentals') }}</h5>
                                        <p><span class="font-weight-bolder"></span>{{ $t('Propertis.demage_responsoble')
                                            }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <!-- Images Section -->
                                <h5>{{ $t('property_detail.Property_Images') }}</h5>
                                <div class="row">
                                    <div v-for="(imagePreview, index) in propertyData.photos" :key="index" class="mb-2">
                                        <img :src="imagePreview.path" alt="Preview"
                                            style="max-width: 100px; max-height: 100px; margin-right: 5px;">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <p v-if="propertyData.approve"><span class="font-weight-bolder">{{
                                    $t('property_detail.Status') }}:</span>{{
                                            $t('property_detail.approved') }}</p>
                                <button v-else class="btn btn-success" @click="approveProperty(propertyData.id)">{{
                                    $t('property_detail.Approve') }}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import Vue from "vue";
import API from '@/api'

export default {
    name: 'attendess',
    data() {
        return {
            propertyData: {
                city: "",
                state: "",
                country: "",
                price: "",
                max_guests: "",
                num_bedrooms: "",
                num_bathrooms: "",
                propertyType: "",
                wifiAvailable: "",
                kitchen: "",
                tv: "",
                description: "",
                imagePreviews: [],
                id: "",
                approve: ""
            },
        }
    },
    methods: {

        getProperty() {
            const id = sessionStorage.getItem('propertyId');
            API.getProperty(id, res => {
                this.propertyData = res.data.data;
            }, err => {
                console.log(err)
            })
        },
        approveProperty(id) {
            API.approveProperty(id, res => {
                if (res.status == 200) {
                    this.propertyData.approve = 1;
                    this.$swal({
                        type: 'success',
                        title: 'Success',
                        text: res.data.message,
                    })
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: res.message,
                    })
                }
            }, err => {
                console.log(err);
            })
        },
        formatTime(time) {
            const [hours, minutes] = time.split(':').map(Number);

            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setSeconds(0);
            date.setMilliseconds(0);
            const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
            const formattedHours = date.getHours() % 12 || 12;
            const formattedMinutes = date.getMinutes().toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        },
    },

    mounted() {
        this.getProperty();
    },
    components: {},
    computed: {
        bathroomAmenitiesDisplay() {
            if (Array.isArray(this.propertyData.bathroom_Amenities)) {
                return this.propertyData.bathroom_Amenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
            } else if (typeof this.propertyData.bathroom_Amenities === 'string') {
                try {
                    const parsedAmenities = JSON.parse(this.propertyData.bathroom_Amenities);
                    if (Array.isArray(parsedAmenities)) {
                        return parsedAmenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
                    }
                } catch (error) {
                    console.error("Error parsing bathroom amenities:", error);
                }
            }
            return this.propertyData.bathroom_Amenities;
        },
        bedroomAmenitiesDisplay() {
            if (Array.isArray(this.propertyData.bedroom_Amenities)) {
                if (this.propertyData.bedroom_Amenities.length === 0) {
                    console.log("Empty array detected");
                    return "[]";

                }
                return this.propertyData.bedroom_Amenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
            } else if (typeof this.propertyData.bedroom_Amenities === 'string') {
                try {
                    const parsedAmenities = JSON.parse(this.propertyData.bedroom_Amenities);
                    if (Array.isArray(parsedAmenities)) {
                        if (parsedAmenities.length === 0) {
                            console.log("Empty array detected after parsing");
                            return "[]";
                        }
                        return parsedAmenities.map(amenity => this.$t('property_detail.' + amenity)).join(', ');
                    }
                } catch (error) {
                    console.error("Error parsing bedroom amenities:", error);
                }
            }
            return this.propertyData.bedroom_Amenities;
        }
    },
    created() {
        if (this.$route.params.id) {
            sessionStorage.setItem('propertyId', this.$route.params.id);
        }
    },
}

</script>